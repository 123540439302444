import Vue from "vue";
import axios from "axios";

axios.defaults.timeout = 1000000; // 请求超网格员信息时
axios.defaults.baseURL = "/"; // 打包显示add_user_discuss
// let str = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiIxNDY5NDk1NzU5MzM1MTczNTQiLCJpc3MiOiJ0cmVhc3VyZSIsImV4cCI6MTcxMjkxMDc3MX0.HdmyvAjjvjFDeN67Dpz0h_4PSSc3cUe4OQbBrukb_Co`

// let str = localStorage.getItem("token");
// console.log(str)
// axios.defaults.headers.common['Authorization'] = str

// http request 请求拦截器，有token值则配置上token值
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    switch (response.data.code) {
      case 0:
        break;
      case 201001: //token报错
        Vue.prototype.$message({
          type: "error",
          message: response.data.msg,
          showClose: true,
          dangerouslyUseHTMLString: true,
        });
        setTimeout(function () {
          var login = localStorage.getItem("loginAddress");

          if (login) {
            if (login == "hz") {
              window.location.href = "/login";
            } else {
              window.location.href = "/";
            }
          } else {
            window.location.href = "/";
          }
        }, 1000);
        break;
      case 201002: //token已过期
        Vue.prototype.$message({
          type: "error",
          message: response.data.msg,
          showClose: true,
          dangerouslyUseHTMLString: true,
        });
        setTimeout(function () {
          var login = localStorage.getItem("loginAddress");

          if (login) {
            if (login == "hz") {
              window.location.href = "/login";
            } else {
              window.location.href = "/";
            }
          } else {
            window.location.href = "/";
          }
        }, 1000);
        break;
      default:
        break;
    }
    return response;
  },
  (error) => {
    if (error.response) {
      // console.log(error, 'now is error axios')
      switch (error.response.status) {
        case 0:
          break;
        case 400:
          error.message = "请求错误(400)";
          break;
        case 401:
          // 这里写清除token的代码
          error.message = "未授权，请重新登录(401)";
          // router.replace({
          //   path: 'login',
          //   query: {
          //     redirect: router.currentRoute.fullPath
          //   } //登录成功后跳入浏览的当前页面
          // })

          break;
        // case 403:
        //   error.message = '拒绝访问(403)'
        //   break
        // case 404:
        //   error.message = `请求出错(404)`
        //   break
        // case 408:
        //   error.message = '请求超时(408)'
        //   break
        // case 500:
        //   error.message = '服务器错误(500)'
        //   break
        // case 501:
        //   error.message = '服务未实现(501)'
        //   break
        // case 502:
        //   error.message = '网络错误(502)'
        //   break
        // case 503:
        //   error.message = '服务不可用(503)'
        //   break
        // case 504:
        //   error.message = '网络超时(504)'
        //   break
        // case 505:
        //   error.message = 'HTTP版本不受支持(505)'
        //   break
        default:
          error.message = `连接出错(${error.response.status})!`;
      }
    }

    console.log("连接出错 ", error);
    return Promise.reject(error.response.data); // 请求错误时， 直接结束

    //return Promise.resolve(error);//请求错误时，不会直接结束，将会继续传到then里面,无论请求成功还是失败，在成功的回调中都能收到通知
  }
);

export default axios;
